import { ColumnTypeIdentifier, RowTypesIdentifier } from '../../../../grid/reduxStore/table.types';
import OptionalRowInput from './OptionalRowInput';

const RenderTableCell = ({ params, value, isPreview }: { params: any; value: string; isPreview?: boolean }) => {
  const rowType = params?.row?.rowType?.toLowerCase();
  const columnType = params?.colDef?.columnType?.toString().toLowerCase();
  const isRenderInputActive =
    params.colDef.columnType === ColumnTypeIdentifier.OPTIONAL && params.row.rowType === RowTypesIdentifier.BODY && !isPreview;
  const valueDisplay = value || '';
  const isRowOptional = params?.row?.isRowOptional ?? false;

  return (
    <span data-testid="render-cell-test" data-testid-row-type={rowType} data-testid-column-type={columnType}>
      {isRenderInputActive ? <OptionalRowInput isRowOptional={isRowOptional} id={params.id} /> : valueDisplay}
    </span>
  );
};

export default RenderTableCell;
