import { useTableRowManipulation } from '../../../../SidePanel/content/TableSettings/RowSettings/useTableRowManipulation';
import Checkbox from '@mui/material/Checkbox';

const OptionalRowInput = ({ id, isRowOptional }: { id: string; isRowOptional: boolean }) => {
  const { toggleRowProperty } = useTableRowManipulation();
  const isCheckboxChecked = isRowOptional;

  const label = { inputProps: { 'aria-label': 'Optional row' } };
  const handleCheckboxChange = async () => {
    await toggleRowProperty('isRowOptional', !isCheckboxChecked, id);
  };

  return <Checkbox {...label} onChange={handleCheckboxChange} checked={isCheckboxChecked} />;
};

export default OptionalRowInput;
