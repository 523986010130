import { useCallback } from 'react';
import { ColumnTypeIdentifier, TableColumnType } from '../../../grid/reduxStore/table.types';
import { commanSeparatedNumber } from '../../../hooks/useFieldValidator';
import { roundDecimalValue } from './helper';
import { isRowBelongsToTotal, isTaxOrDiscountRow, TableRowTypes } from '../../../../../muiTheme/dataGridUtils';
import { NUMBER_COLUMNS_ALLOWING_PRICING } from './variables';
import InputWithTooltips from './components/InputWithTooltips';
import { cellMenu } from './defaultData';
import { GridCellParams } from '@mui/x-data-grid-pro';
import RenderTableCell from './components/RenderTableCell';

const PRICING_COLUMNS = [ColumnTypeIdentifier.PRICE, ColumnTypeIdentifier.SUBTOTAL];
const NUMERIC_COLUMNS = [ColumnTypeIdentifier.MULTIPLIER, ColumnTypeIdentifier.PRICE, ColumnTypeIdentifier.SUBTOTAL];

export function useApplyCustomRulesToColumns(sectionId: string, blockId: string, pricingDecimalPlaces: number, isPreview?: boolean) {
  const setCustomInputToDefaultColumn = (columns: TableColumnType[]): TableColumnType[] => {
    return columns.map((column) => ({
      ...column,
      renderEditCell: (params: GridCellParams) => (
        <InputWithTooltips params={params} blockId={blockId || ''} pricingDecimalPlaces={pricingDecimalPlaces} sectionId={sectionId} />
      ),
      renderCell: (params: GridCellParams) =>
        NUMERIC_COLUMNS.includes(column?.columnType) ? (
          renderNumericCell(params, column.columnType, pricingDecimalPlaces)
        ) : (
          <RenderTableCell isPreview={isPreview} params={params} value={params?.value as string} />
        ),
    }));
  };

  const setColumnsColSpanRuleForFooter = (columns: TableColumnType[]): TableColumnType[] => {
    const FirstColumnIndexAfterId = 1;

    columns[FirstColumnIndexAfterId] = {
      ...columns[FirstColumnIndexAfterId],
      colSpan: (_value, row): number => {
        let colSpan = 1;
        if (row.rowType === TableRowTypes().FOOTER) {
          // -1 because the first row is the id, which is not visible
          colSpan = columns.length - 1;
        }
        if (isRowBelongsToTotal(row)) {
          if (columns.length > NUMBER_COLUMNS_ALLOWING_PRICING) {
            colSpan = columns.length - NUMBER_COLUMNS_ALLOWING_PRICING;
          } else {
            colSpan = 1;
          }
        }
        return colSpan;
      },
    };

    return columns;
  };

  const renderNumericCell = (params: any, columnType: ColumnTypeIdentifier, pricingDecimalPlaces: number, isPreview?: boolean) => {
    const { row, colDef } = params;
    const formaterKey = colDef?.['columnType'].toLowerCase();
    const value = params?.value as string | number;
    const isCustomFormatterApplies = !PRICING_COLUMNS.includes(columnType) || !Number(value) || isTaxOrDiscountRow(row);
    if (!params) return;

    if (!params?.value) {
      return <RenderTableCell isPreview={isPreview} params={params} value="" />;
    }

    if (isCustomFormatterApplies) {
      const valueFormater = row.cellConfig?.[formaterKey]?.valueFormater || '';
      const displayedValue = `${valueFormater === cellMenu[0].sign ? `${valueFormater + '' + value}` : `${value + '' + valueFormater}`}`;
      return <RenderTableCell isPreview={isPreview} params={params} value={displayedValue} />;
    }

    const formattedValue = `$${commanSeparatedNumber(Number(roundDecimalValue(params?.value, pricingDecimalPlaces)))}`;
    return <RenderTableCell isPreview={isPreview} params={params} value={formattedValue} />;
  };

  const applyCustomRulesToColumns = useCallback(
    (columns: TableColumnType[]): TableColumnType[] => setColumnsColSpanRuleForFooter(setCustomInputToDefaultColumn(columns)),
    []
  );

  return { applyCustomRulesToColumns };
}
