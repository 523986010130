import { Box, Typography } from '@mui/material';
import { TableRowType, TotalRowTypesIdentifier } from '../../../grid/reduxStore/table.types';

export const FooterRow = ({ title, value, isEditable }: { title: string; value: string; isEditable: boolean }) => {
  return (
    <Box
      className="py-total-row"
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: 2,
        borderTop: '1px solid lightgray',
        backgroundColor: '#00658C',
        fontWeight: 600,
        color: '#ffffff',
        lineHeight: 'inherit',
      }}
    >
      <div
        style={{
          display: 'table-column',
          width: '33%',
          textAlign: 'right',
        }}
      >
        <Typography variant="body1">{title}</Typography>
      </div>
      <div
        style={{
          display: 'table-column',
          width: '33%',
          textAlign: 'right',
        }}
      >
        {isEditable && (
          <span className="py-custom-cell-wrapper">
            <input data-testid={`${title}-input-field`} className={'py-number-input-no-arrow'} type={'number'} />
          </span>
        )}
      </div>
      <div
        style={{
          display: 'table-column',
          width: '33%',
          textAlign: 'right',
        }}
      >
        <Typography variant="body1">{value}</Typography>
      </div>
    </Box>
  );
};
type PricingTableTotalsFooterType = { totalRows: TableRowType[]; sumOfSubtotalColumn: number };

export const PricingTableTotalsFooter = ({ totalRows, sumOfSubtotalColumn }: PricingTableTotalsFooterType) => {
  return (
    <>
      {totalRows.map((row) => {
        let value = '';
        const isEditable = [TotalRowTypesIdentifier.TAX, TotalRowTypesIdentifier.DISCOUNT].includes(row.rowType);

        if (row.rowType === TotalRowTypesIdentifier.SUBTOTAL) {
          value = sumOfSubtotalColumn.toString();
        }
        return <FooterRow key={row.rowType} title={row.rowType} isEditable={isEditable} value={value} />;
      })}
    </>
  );
};
