import { activitiesClient } from 'services/api';
import { ActivityDto } from './types';

type ActivitiesResponse = {
  activities: ActivityDto[];
};

export const fetchActivitiesForDocument = (documentId: string) => async () => {
  const response = await activitiesClient.get<ActivitiesResponse>(`/document/${documentId}`);
  return response.activities;
};
