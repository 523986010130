import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Switch } from '@mui/material';
import { SecondaryMenuItemTitle } from '../../../component';
import { RootState } from '../../../../grid/reduxStore/Store';
import { selectContentTableRows } from '../../../../grid/reduxStore/editorSlice';
import { useTableRowManipulation } from './useTableRowManipulation';
import { SelectionContext } from '../../../../GridDndEditor/SelectedBlockInfoProvider';

const OptionalRowToggle = ({ rowId }: { rowId: string }) => {
  const { selectedBlockIdByWrapper, selectedSectionId } = useContext(SelectionContext);
  const sectionId = selectedSectionId as string;
  const blockId = selectedBlockIdByWrapper as string;
  const tableRows = useSelector((state: RootState) => selectContentTableRows(state, sectionId, blockId)) || [];
  const { t } = useTranslation();
  const { toggleRowProperty } = useTableRowManipulation();
  const [isToggleAvailable, setIsToggleAvailable] = useState<boolean>(true);

  const isRowOptional = tableRows?.find((row) => row.id === rowId)?.isRowOptional ?? false;
  const optionalRowLabel = { inputProps: { 'aria-label': 'Optional row' } };

  const handleSwitchChange = async () => {
    setIsToggleAvailable(false);
    try {
      await toggleRowProperty('isRowOptional', !isRowOptional);
      setIsToggleAvailable(true);
    } catch (error) {
      setIsToggleAvailable(true);
      throw new Error('Failed to toggle row optional property');
    }
  };

  return (
    <>
      <SecondaryMenuItemTitle>{t('document.grid.table.setting.optional_row')}</SecondaryMenuItemTitle>
      <Grid>
        <Switch
          disabled={!isToggleAvailable}
          data-testid="pricing-row-optional-toggle"
          {...optionalRowLabel}
          checked={isRowOptional}
          onChange={handleSwitchChange}
        />
      </Grid>
    </>
  );
};

export default OptionalRowToggle;
