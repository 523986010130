import { useContext, useEffect, useMemo, useState } from 'react';
import { FormHelperText, Grid } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { TableSettings } from '../../../component';
import SvgIcoTableRowPlugsAfter from 'components/icon/icons/IcoTableRowPlusAfter';
import SvgIcoTableRowPlugsBefore from 'components/icon/icons/IcoTableRowPlusBefore';
import SvgIcoTrash from 'components/icon/icons/IcoTrash';
import IcoHideElement from 'components/icon/icons/IcoHideElement';
import IcoShowElement from 'components/icon/icons/IcoShowElement';
import SvgIcoContentCopy from 'components/icon/icons/IcoContentCopy';

import { setActiveTableSettingsPanel } from '../../../../grid/reduxStore/blockStyleSettingsSlice';
import { useAppDispatch } from '../../../../grid/reduxStore/Store';
import { useTableRowTypeChange } from './useTableRowTypeChange';
import { TableRowAddPositions, useTableRowManipulation } from './useTableRowManipulation';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useTableManipulation } from 'components/editor/GridDndEditor/Block/Table/useTableManipulation';

import {
  ColumnTypeIdentifier,
  RowTypesIdentifier,
  TableType,
  TotalRowTypesIdentifier,
} from 'components/editor/grid/reduxStore/table.types';
import { deprecatedTableRowTypes, TableRowTypes, totalRowTypes, isRowBelongsToTotal } from '../../../../../../muiTheme/dataGridUtils';
import { FeatureFlags } from 'utils/featureFlags';
import { SidePanelContextColumnsPropsType } from '../../SidePanelModelsProvider';
import { SelectionContext } from 'components/editor/GridDndEditor/SelectedBlockInfoProvider';
import { SidePanelProviderContext } from '../../SidePanelModelsProvider';
import OptionalRowToggle from './OptionalRowToggle';
import MultiplierEditingToggle from './MultiplierEditingToggle';

const RowSettings = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const menuTitleInitialState = t('document.grid.table.setting.rows');
  const menuTitleTotalRows = t('document.grid.table.setting.total_rows');
  const subtotalRowHiddenTooltip = t('document.grid.table.setting.tooltip_hide_subtotal');
  const { getTableData } = useTableManipulation();
  const { rowType, handleRowTypeChange } = useTableRowTypeChange();
  const { handleRowAdd, handleRowDuplication, handleDeleteRow, toggleRowProperty } = useTableRowManipulation();

  const { toggledTableSettingsPanel } = useContext(SidePanelProviderContext) as SidePanelContextColumnsPropsType;
  const { selectedBlockIdByWrapper, selectedSectionId } = useContext(SelectionContext);
  const sectionId = selectedSectionId as string;
  const blockId = selectedBlockIdByWrapper as string;
  const isPricingTableAvailable = useFeatureFlag([FeatureFlags.spPricingTable])[FeatureFlags.spPricingTable];
  const selectedModel = toggledTableSettingsPanel?.tableApi.selectedModel || undefined;

  const { row: selectedRow, colDef } = selectedModel as GridCellParams;

  const selectedRowType = selectedRow?.rowType;
  const isTotalsRow = isRowBelongsToTotal(selectedRow);
  const isOptionalToggleActive = rowType === RowTypesIdentifier.BODY;
  const isSelectedRowTypeTotal = selectedRowType === TotalRowTypesIdentifier.TOTAL;

  const [showRow, setShowRow] = useState(!!selectedRow?.isRowHidden);
  const [isSubtotalRowExists, setIsSubtotalRowExists] = useState<boolean>(false);
  const [isTotalRowExists, setIsTotalRowExists] = useState<boolean>(false);

  const columnType = selectedModel?.colDef?.columnType;
  const isEditingToggleActive =
    isPricingTableAvailable && rowType === TableRowTypes().BODY && columnType === ColumnTypeIdentifier.MULTIPLIER;

  const handleHideSubtotalRow = async () => {
    await toggleRowProperty('isRowHidden');
    setShowRow(!showRow);
  };
  const rowButtons = [
    {
      icon: <SvgIcoTableRowPlugsAfter />,
      disabled: isSelectedRowTypeTotal,
      arialLabel: 'row-plus-after',
      fn: () => handleRowAdd(TableRowAddPositions.AFTER),
    },
    {
      icon: <SvgIcoTableRowPlugsBefore />,
      arialLabel: 'row-plus-before',
      fn: () => handleRowAdd(TableRowAddPositions.BEFORE),
    },
    { icon: <SvgIcoContentCopy />, disabled: isSelectedRowTypeTotal, arialLabel: 'content-copy', fn: handleRowDuplication },
    { icon: <SvgIcoTrash />, disabled: isSelectedRowTypeTotal, arialLabel: 'trash', fn: handleDeleteRow },
  ];

  const subtotalRowButtons = [
    { icon: <SvgIcoTableRowPlugsAfter />, arialLabel: 'row-plus-after', fn: () => handleRowAdd(TableRowAddPositions.AFTER) },
    {
      icon: <SvgIcoTableRowPlugsBefore />,
      arialLabel: 'row-plus-before',
      disabled: true,
      fn: () => handleRowAdd(TableRowAddPositions.BEFORE),
    },
    { icon: <SvgIcoContentCopy />, arialLabel: 'content-copy', disabled: true, fn: handleRowDuplication },
    {
      icon: showRow ? <IcoHideElement data-testid="icon-hide" /> : <IcoShowElement data-testid="icon-show" />,
      arialLabel: 'hide-row',
      fn: () => handleHideSubtotalRow(),
    },
  ];

  const activeRowButtons = selectedRowType === TableRowTypes(isPricingTableAvailable)['SUBTOTAL'] ? subtotalRowButtons : rowButtons;
  const selectOptions = isTotalsRow ? totalRowTypes : deprecatedTableRowTypes;
  const menuTitle = isTotalsRow ? menuTitleTotalRows : menuTitleInitialState;
  const selectDisabled = useMemo(() => {
    const selectedRowType = selectedRow?.rowType;
    return (
      selectedRowType === TableRowTypes(isPricingTableAvailable)['SUBTOTAL'] ||
      selectedRowType === TableRowTypes(isPricingTableAvailable)['TOTAL']
    );
  }, [selectedRow, isPricingTableAvailable]);

  const handleBackButtonClick = () => {
    dispatch(setActiveTableSettingsPanel({ type: null }));
  };

  const checkDisabled = (option: string): boolean => {
    if (isSubtotalRowExists && option === TableRowTypes(isPricingTableAvailable)['SUBTOTAL']) {
      return true;
    }
    return isTotalRowExists && option === TableRowTypes(isPricingTableAvailable)['TOTAL'];
  };

  useEffect(() => {
    if (selectedRow && colDef) {
      setShowRow(selectedRow?.isRowHidden ?? false);
    }
  }, [selectedModel]);

  useEffect(() => {
    if (!toggledTableSettingsPanel || !selectedBlockIdByWrapper) return;
    if (!sectionId) throw new Error('Section id cannot be empty');
    const tableContentData = getTableData(blockId, sectionId) as TableType;

    if (!tableContentData) return;

    const tableRows = tableContentData?.rows;
    tableRows?.forEach((row) => {
      if (row.rowType === TableRowTypes(isPricingTableAvailable)['TOTAL']) {
        setIsTotalRowExists(true);
      }
      if (row.rowType === TableRowTypes(isPricingTableAvailable)['SUBTOTAL']) {
        setIsSubtotalRowExists(true);
      }
    });
  }, [toggledTableSettingsPanel, sectionId, selectedBlockIdByWrapper]);

  return (
    <TableSettings
      iconButtons={activeRowButtons}
      handleBackButtonClick={handleBackButtonClick}
      handleSelectChange={handleRowTypeChange}
      helpText={t('document.grid.table.setting.update_select_row_type')}
      panelTitle={t('document.grid.table.setting.row_type')}
      selectId="select-row-type"
      selectOptions={selectOptions}
      checkDisabled={checkDisabled}
      selectValue={rowType}
      menuTitle={menuTitle}
      testId="table-row-settings"
      disabled={selectDisabled}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        {isOptionalToggleActive && <OptionalRowToggle rowId={selectedRow.id} />}
        {isEditingToggleActive && <MultiplierEditingToggle />}
      </Grid>
      {showRow && <FormHelperText data-testid={'subtotal-row-hidden-help-text'}>{subtotalRowHiddenTooltip}</FormHelperText>}
    </TableSettings>
  );
};

export default RowSettings;
